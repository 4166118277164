import React, { useEffect, useState } from "react";

import {
    Box,
    Checkbox,
    Chip,
    Grid,
    InputLabel,
    Select,
    ListItemText,
    MenuItem,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import useStyles from "./style";
import moment from "moment";

export default function AddressForm(props) {
    var classes = useStyles();

    const reportOptions = [
        { name: 'Order Metrics Daily', type: 'ordermetricsday', for: ['seller', 'vendor'] },
        { name: 'Order Metrics Hourly', type: 'ordermetricshour', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Weekly', type: 'ordermetricsasinweek', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Daily', type: 'ordermetricsasinday', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Hourly', type: 'ordermetricsasinhour', for: ['seller', 'vendor'] },
        { name: "3P Report", type: "3preport", for: ['seller', 'vendor'] },
        { name: "Share Of Voice", type: "shareofvoice", for: ['seller', 'vendor'] },
        { name: 'Geographic Insights', type: 'geographicinsights', for: ['seller'] },
        { name: 'Campaign Reports', type: 'campaignreports', for: ['seller', 'vendor'] },
        { name: 'Sales Traffic Report', type: 'salestraffic', for: ['seller'] },
        { name: "Vendor Sales Report", type: "vendorsalesreport", for: ['vendor'] },
        { name: "Catalog Items Refresh", type: "catalogRefresh", for: ['seller', 'vendor'] },
        // { name: 'Keywords By ASIN', type: 'jskeywordbyasin' },
        // { name: 'Sales Estimate Data', type: 'jssalesestimatedata' },
        // { name: 'Share of Voice', type: 'shareofvoice' },
        // { name: 'Products and Reviews', type: 'amazonproductreview' },
        // { name: 'Brand Segment by Day', type: 'jssegmentbyday' }
        { name: 'Brand Segment Reports', type: 'jssegmentreports', for: ['seller', 'vendor'] },
        { name: 'Search Terms Report', type: 'searchtermsreport', for: ['seller', 'vendor'] },
        { name: 'Keywords Report', type: 'keywordsreport', for: ['seller', 'vendor'] },
        { name: "Vendor Traffic Report", type: "vendortrafficreport", for: ['vendor'] },
        { name: "Promotion Performance", type: "promotionperformance", for: ['vendor', 'seller'] },
        { name: "Coupon Performance", type: "couponperformance", for: ['vendor', 'seller'] },
        { name: "Product Pricing", type: "productpricing", for: ['vendor', 'seller'] },
        { name: "Vendor NPP Margin Report", type: "vendornppmarginreport", for: ['vendor'] },
        { name: "Inventory Reports", type: "inventoryreports", for: ['vendor', 'seller'] },
        { name: "AM Rules Default", type: "amRulesDefault", for: ['vendor', 'seller'] },
        { name: "Real Time Vendor Sales Report", type: "realTimeVendorSales", for: ['vendor'] },
        { name: "Real Time Vendor Traffic Report", type: "realTimeVendorTraffic", for: ['vendor'] },
        { name: "Real Time Vendor Inventory Report", type: "realTimeVendorInventory", for: ['vendor'] },
        { name: "MBR Export", type: "mbrExport", for: ['vendor', 'seller'] },
        { name: "API Config Reviews ASINs", type: "apiConfigReviewsAsins", for: ['vendor', 'seller'] },
        { name: "API Config ASINs", type: "apiConfigAsins", for: ['seller'] },
        { name: "API Config Segments", type: "apiConfigSegments", for: ['vendor', 'seller'] },
        { name: "Catalog Item Favicon Refresh", type: "s3CatalogFavicon", for: ['vendor', 'seller'] },
        { name: "JS Segment Items Favicon Refresh", type: "s3JsCompetitorFavicon", for: ['vendor', 'seller'] },
        { name: "Rating & Reviews", type: "generateReviews", for: ['vendor', 'seller'] },
    ]
    const [reportList, setReportList] = useState(reportOptions);

    const asinConfigOptions = [
        { label: "All ASINs", value: "ALL" },
        { label: "Top 80 ASINs", value: "TOP_80" },
    ];
    const [asinConfig, setAsinConfig] = useState("ALL");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isError, setIsError] = useState(false);

    const setDateError = (event) => setIsError(!!event);

    useEffect(() => {
        if (!reportList.map((item) => item.type).includes(props?.selectedReportType)) {
            props?.setSelectedReportType('');
        }
    }, [reportList]);

    return (
        <div className={classes.form}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.campaignControl}>
                        <InputLabel>
                            Select Vendor
                        </InputLabel>
                        <Select
                            value={props?.selectedVendor}
                            renderValue={() => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: "4px" }}>
                                    {props?.selectedVendor.map((value) => (
                                        <Chip
                                            size="small"
                                            variant="outlined"
                                            key={value}
                                            label={props?.vendorList?.find((item) => item.vendorcode === value)?.vendorname}
                                        />
                                    ))}
                                </Box>
                            )}
                            multiple={true}
                            onChange={(e) => {
                                if (e.target.value.includes("ALL")) {
                                    props?.setSelectedVendor(["ALL"]);
                                    setReportList(reportOptions.filter((item) => !['Catalog Items Refresh'].includes(item.name)));
                                } else {
                                    props?.setSelectedVendor(e.target.value);
                                    const accountType = props?.vendorList?.find((vendor) => vendor.vendorcode === e.target.value.at(-1))?.accounttype || 'All';
                                    setReportList(reportOptions.filter((item) => item.for.includes(accountType)));
                                }
                            }}
                            label="Select Vendors"
                            disabled={props?.vendorList && props?.vendorList?.length === 0 ? true : false}
                        >
                            {
                                props?.vendorList ? (
                                    props?.vendorList.map((vendor, i) => {
                                        return (
                                            <MenuItem key={i} value={vendor?.vendorcode}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={props?.selectedVendor?.includes(vendor?.vendorcode)}
                                                />
                                                <ListItemText primary={vendor?.vendorname}/>
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem value={0}>
                                        <i>No Vendor</i>
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.campaignControl}>
                        <InputLabel>
                            Select Report Type
                        </InputLabel>
                        <Select
                            value={props?.selectedReportType || ''}
                            onChange={(e) => {
                                props?.setSelectedReportType(e.target.value);
                            }}
                            label="Select Report Type"
                            disabled={reportList && reportList.length === 0 ? true : false}
                        >
                            {
                                reportList ? (
                                    reportList.map((report, i) => {
                                        return (
                                            <MenuItem key={i} value={report?.type}>
                                                {report?.name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem value={0}>
                                        <i>No Report Type</i>
                                    </MenuItem>
                                )}
                        </Select>
                        {
                            props?.selectedReportType === "jssegmentreports" &&
                            props?.selectedVendor?.length === 1 &&
                            !props?.selectedVendor?.includes("ALL") &&
                            <>
                                <TextField
                                    value={props.segmentId}
                                    onChange={(e) => props.setSegmentId(e.target.value)}
                                    label="Segment ID"
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: 10 }}
                                    required
                                />
                            </>
                        }
                    </FormControl>
                </Grid>
                {props?.selectedReportType === "apiConfigReviewsAsins"
                ?
                <>
                <Grid item xs={12} sm={6}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={asinConfig}
                            defaultChecked={"ALL"}
                            onChange={(e) => setAsinConfig(e.target.value)}
                        >
                            {asinConfigOptions.map((asinConfigOption) => 
                                <FormControlLabel key={asinConfigOption.value} value={asinConfigOption.value} control={<Radio color="primary" />} label={asinConfigOption.label} />
                            )}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                </>
                :
                <>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '24px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="Start Date"
                            value={startDate}
                            format={"MM/dd/yy"}
                            disabled={!props?.selectedVendor || !props?.selectedReportType}
                            maxDate={moment()}
                            onError={setDateError}
                            onChange={setStartDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '24px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="End Date"
                            value={endDate}
                            format={"MM/dd/yy"}
                            disabled={!props?.selectedVendor || !props?.selectedReportType}
                            onError={setDateError}
                            minDate={moment(startDate)}
                            maxDate={moment()}
                            onChange={setEndDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                </>
                }
                <Grid item xs={12} style={{ textAlign: 'end', paddingRight: '36px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={isError || !props?.selectedReportType}
                        onClick={() => props?.generateReport(
                            props?.selectedVendor?.join(),
                            props?.selectedReportType,
                            startDate,
                            endDate,
                            asinConfig,
                            )}
                    >
                        Generate Data
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}