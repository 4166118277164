/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import _ from "lodash";
import "date-fns";
import axios from "axios";
import moment from "moment";
import * as jsonRefs from "json-refs";
import { updatedDiff } from "deep-object-diff";
import { concat, mergeDeepWith } from "ramda";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";
import { CloudUpload } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/Check";
import { Validator } from "jsonschema";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig, useUserState } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";
import { findMarketplace } from "../../healpers/utilityFunctions";

import SEOUpdateUploadTemplate from "../../healpers/assets/SEOUpdateUploadTemplate.xlsx";

const DELETE_KEYS = ["marketplace_id", "language_tag"];

const SKIP_FIELDS = {
  // Very deeply nested fields which cause issue when simplifying and rendering
  // purchasable_offer: true,
  cable: true
};

function deFlattenSchema(nestedKey, value) {
  const keys = nestedKey.split("$");
  let lastKey = keys.slice(keys.length - 1)[0];
  let obj = {};
  if (lastKey.includes("[]")) {
    lastKey = lastKey.replace(/\[\]\d/g, "");
    obj[lastKey] = [value];
  } else {
    obj[lastKey] = value;
  }

  let recentKey = lastKey;
  for (let key of keys.slice(0, keys.length - 1).reverse()) {
    if (key.includes("[]")) {
      key = key.replace(/\[\]\d/g, "");
      obj[key] = [{ ...obj }];
    } else {
      obj[key] = { ...obj };
    }
    if (recentKey) {
      delete obj[recentKey];
    }
    recentKey = key;
  }

  return obj;
}

function convertItem(type, value) {
  switch (type) {
    case "string":
      return value;
    case "integer":
      return parseInt(value);
    case "number":
      return parseFloat(value);
    case "boolean":
      return typeof(value) === "boolean" ? value : value.toLowerCase() == "true" ? true : false;
    default:
      console.log(type, value);
      return value;
  }
}

// Converts the simplified data back to payload
function processData(data, schema) {
  const STATIC_VALUES = {
    marketplace_id: findMarketplace(JSON.parse(localStorage.getItem("selectedVendor"))?.vendorcode),
    language_tag: "en_US",
  };
  if (typeof data == "undefined" || typeof schema == "undefined") {
    return undefined;
  }
  if (["string", "number", "boolean"].indexOf(typeof data) >= 0) {
    if (schema.type == "array") {
      return [processData(data, schema.items)];
    } else if (schema.type == "object") {
      const value = {};
      for (const key of Object.keys(schema.properties)) {
        const dataType = schema.properties[key].type;
        if (STATIC_VALUES[key]) {
          value[key] = STATIC_VALUES[key];
        } else if (typeof data != "undefined") {
          if (
            ["string", "boolean", "number", "integer"].indexOf(
              schema.properties[key].type
            ) >= 0
          ) {
            value[key] = convertItem(dataType, data);
          } else {
            value[key] = processData(data, schema.properties[key]);
          }
        }
      }
      return value;
    }
    return convertItem(schema.type, data);
  } else if (Array.isArray(data)) {
    const value = [];
    if (schema.type == "array") {
      for (const item of data) {
        value.push(processData(item, schema.items));
      }
    } else {
      throw new Error("Schema type is not array");
    }
    return value;
  } else if (typeof data == "object") {
    if (schema.type == "array") {
      return [processData(data, schema.items)];
    } else if (schema.type == "object") {
      const value = {};

      const isSimilarSchema = Object.keys(data).reduce((bool, key) => {
        return bool ? bool : Object.keys(schema.properties).indexOf(key) >= 0;
      }, false);
      if (!isSimilarSchema && Object.keys(data).length > 0) {
        console.log(
          "Schema not same. case not handled",
          data,
          schema.properties
        );
        throw new Error("Schema not same. case not handled");
      }
      for (const key of Object.keys(schema.properties)) {
        if (STATIC_VALUES[key]) {
          value[key] = STATIC_VALUES[key];
        } else if (typeof data[key] != "undefined") {
          if (
            ["string", "boolean", "number", "integer"].indexOf(
              schema.properties[key].type
            ) >= 0
          ) {
            value[key] = convertItem(schema.properties[key].type, data[key]);
          } else {
            value[key] = processData(data[key], schema.properties[key]);
          }
        }
      }
      return value;
    }
  } else throw new Error("Unseen type.Case not handled");
}

function validatePayload(data, schema) {
  const payload = _.cloneDeep(data);

  const v = new Validator();
  const obj = v.validate(payload, schema);

  return obj;
}

function makePayload(formData, productSchema, originalData) {
  const { required = {}, optional = {}, extra = {} } = formData;

  const payload = [required, optional, extra].reduce((obj, fields) => {
    // Convert all the fields back to their original values
    const data = Object.keys(fields).reduce((data, key) => {
      data[key] = processData(fields[key], productSchema.properties[key]);
      return data;
    }, {});
    return { ...obj, ...data };
  }, {});

  for (const key in SKIP_FIELDS) {
    if (originalData.attributes[key]) {
      payload[key] = originalData.attributes[key];
    }
  }

  return payload;
}

function parseItem(type, data) {
  switch (type) {
    case "string":
      return data.toString();
    case "number":
    case "integer":
      return isNaN(Number(data)) ? undefined : Number(data);
    case "boolean":
      if (!isNaN(Number(data))) {
        return Boolean(Number(data));
      } else {
        return "true" == data;
      }
    default:
      console.log(type);
      throw new Error("type not matched");
  }
}

// Simplifies the schema
function processItem(param) {
  const item = _.cloneDeep(param);
  const { type } = item;
  delete item.examples;

  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    if (item?.anyOf) {
      for (const value of item.anyOf) {
        if (value?.enum) {
          value.title = "Select from options";
        } else {
          value.title = "Enter custom value";
        }
      }
    }
    return item;
  }

  if (type == "object") {
    let { properties, required } = item;
    required = required.filter((key) => {
      if (DELETE_KEYS.indexOf(key) >= 0) {
        delete properties[key];
        return false;
      }
      return true;
    });
    if (Object.keys(properties).length == 0) return null;

    for (const key of Object.keys(properties)) {
      properties[key] = processItem(properties[key]);
    }

    item.properties = properties;
    item.required = required;
    return item;
  }

  if (type == "array") {
    let { selectors, maxItems, maxUniqueItems = 0 } = item;

    if (maxItems && maxItems == 1) {
      return processItem(item.items);
    }

    if (selectors) {
      selectors = selectors.filter((key) => DELETE_KEYS.indexOf(key) < 0);
      if (
        selectors.length == 0 ||
        (selectors.length == 1 && maxUniqueItems <= 1)
      ) {
        const { title, description } = item;
        item.items.title = title;
        item.items.description = description;
        return processItem(item.items);
      } else if (selectors.length >= 1 && maxUniqueItems >= 1) {
        item.maxItems = maxUniqueItems;
      }
    }
    item.items = processItem(item.items);
    return item;
  }

  throw new Error("Not a recognized type");
}

// Simplifies the payload
function processPayloadItem(item, matchSchema) {
  const PRIMITIVE_TYPES = ["string", "number", "boolean", "null"];

  // handle the primitive types
  if (
    ["string", "number", "integer", "boolean", "null"].indexOf(
      matchSchema.type
    ) >= 0
  ) {
    let value = null;
    while (true) {
      if (typeof item == "object") {
        if (item === null) {
          item = "";
        } else {
          let keys = Object.keys(item);
          keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
          if (keys.length != 1) {
            console.error(
              "SOLVE THIS: keys length should not be greater than 0",
              keys,
              matchSchema
            );
          }
          item = item[keys[0]];
        }
      } else if (PRIMITIVE_TYPES.indexOf(typeof item) >= 0) {
        value = parseItem(matchSchema.type, item);
        break;
      } else {
        throw new Error("unknown type seen");
      }
    }
    if (matchSchema?.editable === false && value != "") {
      matchSchema["readOnly"] = true;
      delete matchSchema["anyOf"];
      delete matchSchema["oneOf"];
      delete matchSchema["enum"];
      delete matchSchema["enumNames"];
    }
    return [value, matchSchema];
  }

  if (matchSchema.type == "array") {
    const values = [];

    let items = _.cloneDeep(item);
    if (!Array.isArray(items)) {
      let keys = Object.keys(items);
      keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
      if (keys.length != 1)
        throw new Error("Keys cannot be of length anything other than 1");
      items = [item[keys[0]]];
    }
    for (const value of items) {
      const [obj, schema] = processPayloadItem(value, matchSchema.items);
      matchSchema.items = schema;
      values.push(obj);
    }
    return [values, matchSchema];
  }
  if (matchSchema.type == "object") {
    const value = {};
    let items = _.cloneDeep(item);
    if (Array.isArray(items)) {
      if (items.length != 1 && matchSchema.title != "External Product ID") {
        console.error(
          "SOLVE THIS: keys length should not be greater than 0",
          items,
          matchSchema
        );
      }
      items = items[0];
    }
    for (const key of Object.keys(items)) {
      if (DELETE_KEYS.indexOf(key) >= 0) continue;
      const [obj, schema] = processPayloadItem(
        items[key],
        matchSchema.properties[key]
      );
      matchSchema.properties[key] = schema;
      value[key] = obj;
    }
    return [value, matchSchema];
  }

  throw new Error("Type did not match anything");
}

function markNotRequired(schema) {
  const item = _.cloneDeep(schema);
  const { type } = item;

  // Delete required section
  delete item.required;
  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    return item;
  } else if (type == "object") {
    const { properties } = item;
    for (const key of Object.keys(properties)) {
      properties[key] = markNotRequired(properties[key]);
    }
    item.properties = properties;
    return item;
  } else if (type == "array") {
    delete item.minItems;
    item.items = markNotRequired(item.items);
    return item;
  }
}

function flatten_payload(key, payload) {
  const obj = {};
  if (["string", "number", "boolean"].indexOf(typeof payload) >= 0) {
    obj[key] = payload;
  } else if (Array.isArray(payload)) {
    for (const i in payload) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload("", payload[i])
      )) {
        const objKey = key + "[]" + i + itemKey;
        obj[objKey] = value;
      }
    }
  } else if (typeof payload == "object") {
    for (const property of Object.keys(payload)) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload(property, payload[property])
      )) {
        const objKey = key + "$" + itemKey;
        obj[objKey] = value;
      }
    }
  } else {
    console.log(key, payload);
    throw new Error("not a recognized type");
  }
  return obj;
}

function customSchemaChanges(schema) {
  // Bullet_points is an array with a max 5 items
  if (schema.properties.bullet_point) {
    schema.properties.bullet_point = {
      ...schema.properties.bullet_point,
      selectors: ["marketplace_id", "language_tag", "value"],
      maxUniqueItems: 5,
      maxItems: 5,
    };
  }

  // merchant_suggested_asin is a required field
  schema.required = [...schema.required, "merchant_suggested_asin"];
  return schema;
}

function createSchema(productSchema, attributes) {
  const schema = {
    type: "object",
    properties: {},
    required: ["required", "optional", "extra"],
  };

  schema.properties["required"] = productSchema.required.reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      required: productSchema.required,
      title: "Required",
      description:
        "All these fields are required and must not be empty for this listing.",
    }
  );

  schema.properties["optional"] = Object.keys(attributes).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;

      // Skip if already covered in required fiels
      if (schema.properties.required.properties[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Optional",
      description:
        "All these fields are optional but already exist in the current listing.",
    }
  );

  schema.properties["extra"] = Object.keys(productSchema.properties).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      if (
        schema.properties.required.properties[key] ||
        schema.properties.optional.properties[key]
      )
        return obj;
      let itemSchema = processItem(productSchema["properties"][key]);
      itemSchema = markNotRequired(itemSchema);
      obj.properties[key] = itemSchema;
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Extra",
      description:
        "All these fields are optional but don't yet exist in the current listing.",
    }
  );

  return schema;
}

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Custom Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const CustomSuccessMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Success</Typography>
      <List>
        {props.success.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <SuccessIcon style={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "green" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default function CatalogItems(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();
  const userState = useUserState();
  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [mainTableDataSelectedRows, setMainTableDataSelectedRows] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [headerNames, setHeaderNames] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [seoUploadInProgress, setSeoUploadInProgress] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const response = await axios.get(
          BASE_URL + api.catalogGetAPI,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          const filteredKeys = [
            "id",
            "created_at",
            "updated_at",
            "vendorCode",
            "supported",
          ];
          const headerNames = Object.keys(data[0])
            .filter((key) => filteredKeys.indexOf(key) < 0)
            .map((key) => {
              return {
                headerName: key
                  .replaceAll(/[A-Z]/g, (x) => ` ${x}`)
                  .replace(/^\w/g, (x) => x.toUpperCase()),
                field: key,
              };
            });
          setHeaderNames(headerNames);
          setmainTableData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setMainTableDataSelectedRows([]);
    };
  }, [vendorState.selected]);

  function customPayloadChanges(payload, asin, marketplaceId) {
    // Auto populate merchant_suggested_asin
    if (payload.attributes.merchant_suggested_asin) {
      const merchant_suggested_asin =
        payload.attributes.merchant_suggested_asin;
      const messages = [];
      if (merchant_suggested_asin.length > 1) {
        messages.push("More than one asin in merchant_suggested_asin");
      }
      if (merchant_suggested_asin[0].value != asin) {
        messages.push("Requested asin and payload asin not matching");
      }
      setErrorMessage(messages);
    } else {
      payload.attributes.merchant_suggested_asin = [
        {
          value: asin,
          marketplace_id: marketplaceId,
        },
      ];
    }

    return payload;
  }

  const uploadCSV = async (file) => {
    const reader = new FileReader();
    setErrorMessage([]);
    setSuccessMessage([]);

    reader.onload = async (evt) => {
      setfullPageLoader(true);
      const errors = [];
      const success = [];
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "array" });
        const wsData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
        });

        for (const item of wsData) {
          const asin = item.root$asin;
          const sku = item.root$sku;
          const marketplaceId = item.root$marketplaceId;
          const productType = item.root$productType;
          const productInfo = `${asin} ${sku} ${marketplaceId} `;

          let updatedDataObj = {};
          let liveDataObj = {};
          let response = null;
          let originalData = null;
          let productSchema = null;
          let refResolvedOriginalSchema = null;

          try {
            response = await axios.get(
              BASE_URL + api.spListing + vendorState.selected,
              tokenConfig({
                asin,
                sku,
                marketplaceId,
              })
            );
            originalData = response.data.data.payload;
            productSchema = response.data.data.productSchema.schema;
            refResolvedOriginalSchema = await jsonRefs.resolveRefs(
              customSchemaChanges(productSchema)
            );
            refResolvedOriginalSchema = refResolvedOriginalSchema.resolved;
          } catch (err) {
            console.log(err);
            const { response } = err;
            if (response && response.data && response.data.message) {
              const { message } = response.data;
              errors.push(productInfo + message);
            } else {
              errors.push(productInfo + "Error occurred while loading data");
            }
            continue;
          }

          try {
            const payload = customPayloadChanges(
              originalData,
              asin,
              marketplaceId
            );

            const attributes = payload.attributes;
            const schema = createSchema(refResolvedOriginalSchema, attributes);

            const formData = Object.keys(attributes).reduce(
              (obj, key) => {
                if (SKIP_FIELDS[key]) return obj;

                const isRequired =
                  refResolvedOriginalSchema.required.indexOf(key) >= 0;

                const rootKey = isRequired ? "required" : "optional";
                const itemSchema = schema.properties[rootKey].properties[key];

                const [convertedItem, updatedSchema] = processPayloadItem(
                  attributes[key],
                  itemSchema
                );
                obj[rootKey][key] = convertedItem;
                schema.properties[rootKey].properties[key] = updatedSchema;

                return obj;
              },
              {
                required: {},
                optional: {},
              }
            );

            const flat_data = flatten_payload("root", {
              asin,
              sku,
              marketplaceId,
              productType,
              ...formData,
            });

            // Updated data
            for (const [key, value] of Object.entries(item)) {
              if (value == "") {
                continue;
              }
              const obj = deFlattenSchema(key, value);
              updatedDataObj = mergeDeepWith(concat, updatedDataObj, obj);
            }
            const updatedAttributes = makePayload(
              {
                required: updatedDataObj.root.required,
                optional: updatedDataObj.root.optional,
                extra: updatedDataObj.root.extra,
              },
              productSchema,
              originalData
            );

            // Live data
            for (const [key, value] of Object.entries(flat_data)) {
              if (value == "") {
                continue;
              }
              const obj = deFlattenSchema(key, value);
              liveDataObj = mergeDeepWith(concat, liveDataObj, obj);
            }
            const liveAttributes = makePayload(
              {
                required: liveDataObj.root.required,
                optional: liveDataObj.root.optional,
                extra: liveDataObj.root.extra,
              },
              response.data.data.productSchema.schema,
              response.data.data.payload
            );

            const diff = updatedDiff(liveAttributes, updatedAttributes);
            if (Object.keys(diff).length === 0) {
              errors.push(`${productInfo} update failed. No updates to make. Check with admin if problem persists`);
              continue;
            }

            const patches = [];
            let payloadError = false;

            for (const item of Object.keys(diff)) {
              const validator = new Validator();
              const validation = validator.validate(
                updatedAttributes[item],
                refResolvedOriginalSchema.properties[item]
              );
              if (validation.errors.length > 0) {
                payloadError = true;
                validation.errors.forEach((error) => {
                  errors.push(`${productInfo} update failed. Schema error on field ${item} ${error.schema.title} ${error.message}. Check with admin if problem persists`);
                });
              } else {
                patches.push({
                  op: "replace",
                  path: `/attributes/${item}`,
                  value: updatedAttributes[item],
                });
              }
            }

            if (!payloadError) {
              try {
                const payload = {
                  productType,
                  patches,
                  updatedAttributes,
                };
                await axios.post(
                  BASE_URL + api.spPatchListing + vendorState.selected,
                  payload,
                  tokenConfig({
                    asin,
                    sku,
                    marketplaceId,
                  })
                );
                success.push(`${productInfo} update successful.`);
              } catch (err) {
                const { response } = err;
                if (response?.data?.errors) {
                  for (const error of response?.data?.errors) {
                    errors.push(`${productInfo} update failed. ${error}`);
                  }
                  errors.push(
                    `${productInfo} update failed. ${response?.data?.message}. Check with admin if problem persists`
                  );
                } else if (response?.data?.message) {
                  errors.push(
                    `${productInfo} update failed. ${response?.data?.message}. Check with admin if problem persists`
                  );
                } else {
                  errors.push(
                    `${productInfo} update failed. ${err?.message}. Check with admin`
                  );
                }
              }
            }
          } catch (err) {
            console.log(err);
            const { response } = err;
            if (response && response.data && response.data.message) {
              const { message } = response.data;
              errors.push(productInfo + message);
            } else {
              errors.push(productInfo + "Error occurred while loading data");
            }
          }
        }
        setfullPageLoader(false);
      } catch (err) {
        console.log("Error", err);
        const errorBlog = new Blob(
          [JSON.stringify(err, Object.getOwnPropertyNames(err), 2)],
          {
            type: "application/json",
          }
        );
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.download = "ERROR_LOG" + ".json";
        downloadAnchorNode.href = window.URL.createObjectURL(errorBlog);
        downloadAnchorNode.click();
        setErrorMessage(["Unknown error has occurred. Contact the admin"]);
      } finally {
        setfullPageLoader(false);
        const partialUpdateStatus = XLSX.utils.book_new();
        const successWs = XLSX.utils.json_to_sheet(success.map((item)=> { return { Message: item } }));
        const failedWs = XLSX.utils.json_to_sheet(errors.map((item)=> { return { Message: item } }));
        XLSX.utils.book_append_sheet(partialUpdateStatus, successWs, "SUCCESS");
        XLSX.utils.book_append_sheet(partialUpdateStatus, failedWs, "FAILED");
        XLSX.writeFile(partialUpdateStatus, "BULK UPLOAD STATUS.xlsx");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Catalog Items"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Catalog
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                All Catalog Items
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {errorMessage.length > 0 && (
              <CustomErrorsMessages errors={errorMessage} />
            )}
            {successMessage.length > 0 && (
              <CustomSuccessMessages success={successMessage} />
            )}
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      gap: "8px",
                      alignItems: "start",
                    }}
                  >
                    <div style={{
                      display: "flex",
                      gap: "8px",
                    }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        onClick={() => exportFunctionRef.current.onBtnExport()}
                      >
                        Export
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        disabled={(mainTableData || []).length === 0}
                        onClick={() => {
                          const catalogItems = mainTableData?.map((item) => {
                            return {
                              "Vendor Code": vendorState?.selected || "",
                              ASIN: item.asin || "",
                              SKU: item.sku || "",
                              Title: item.itemName || "",
                              Status: item.status || "",
                              Price: item.price || "",
                              "Product Type": item.productType || "",
                              Category: item.category || "",
                              "Sub Category": item.subCategory || "",
                              "Updated At": item.updatedAt || "",
                            }
                          });
                          const worksheet = XLSX.utils.json_to_sheet(catalogItems);
                          const workbook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workbook, worksheet, "Catalog");

                          XLSX.writeFile(workbook, `Catalog Items-${vendorState?.selected}-${moment().format("MM-DD-YYYY")}.xlsx`);
                        }}
                      >
                        Export Catalog
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      disabled={(mainTableDataSelectedRows || []).length === 0}
                      onClick={() => {
                        const catalogItems = mainTableDataSelectedRows?.map((item) => {
                          return {
                            ASIN: item.asin || "",
                            SKU: item.sku || "",
                            Title: item.itemName || "",
                            BP1: item.bulletPoints?.[0] || "",
                            BP2: item.bulletPoints?.[1] || "",
                            BP3: item.bulletPoints?.[2] || "",
                            BP4: item.bulletPoints?.[3] || "",
                            BP5: item.bulletPoints?.[4] || "",
                            Description: item.itemDescription || "",
                            "PT": item.productType || "",
                            "Vendor Code": vendorState?.selected || "",
                            "VC Vendor Code": item.vcVendorCode || "",
                          }
                        });
                        const worksheet = XLSX.utils.json_to_sheet(catalogItems);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                        XLSX.writeFile(workbook, `SEO Export-${vendorState?.selected}-${moment().format("MM-DD-YYYY")}.xlsx`);
                      }}
                    >
                      SEO Export
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      gap: "8px",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "space-between"
                      }}
                    >
                      <label htmlFor="upload-csv">
                        <input
                          style={{ display: "none" }}
                          id="upload-csv"
                          name="upload-csv"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={(e) => uploadCSV(e.target.files[0])}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="span"
                          startIcon={<CloudUpload />}
                        >
                          Catalog Upload
                        </Button>
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "space-between"
                      }}
                    >
                      <a
                        href={SEOUpdateUploadTemplate}
                        download="SEOUpdateUploadTemplate.xlsx"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<PrintIcon />}
                        >
                          SEO Template
                        </Button>
                      </a>
                      <label htmlFor="upload-seo">
                        <input
                          style={{ display: "none" }}
                          id="upload-seo"
                          name="upload-seo"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={async (e) => {
                            const reader = new FileReader();
                            reader.onload = async (event) => {
                              try {
                                setSeoUploadInProgress(true);
                                const workbook = XLSX.read(event.target.result, {
                                  type: "array",
                                });
                                const worksheetName = workbook.SheetNames[0];
                                const worksheet = workbook.Sheets[worksheetName];
                                const data = XLSX.utils.sheet_to_json(worksheet);
                                if (data.length > 0) {
                                  let isFileValid = true;
                                  for (let index = 0; index < data.length; index++) {
                                    if (
                                      !data[index]["ASIN"] ||
                                      !data[index]["SKU"] ||
                                      !data[index]["Title"] ||
                                      !data[index]["BP1"] ||
                                      !data[index]["PT"] ||
                                      !data[index]["Vendor Code"] ||
                                      (data[index]["Vendor Code"].endsWith("VC") && !data[index]["VC Vendor Code"])
                                    ) {
                                      window.alert(
                                        `Missing required field on row ${
                                          index + 1
                                        }. Check the file and Try again.`
                                      );
                                      isFileValid = false;
                                      break;
                                    }
                                  }
                                  if (isFileValid) {
                                    try {
                                      const response = await axios({
                                        method: "post",
                                        url: BASE_URL + api.uploadSeoUpdate,
                                        data: {
                                          seoUpdate: data,
                                          uploader: userState.userData?.email,
                                        },
                                      });
                                      if (response.data?.status === "Success") {
                                        window.alert(response.data?.message + "\nCheck the output file for more details.");
                                        const wb = XLSX.utils.book_new();
                                        const ws = XLSX.utils.json_to_sheet(response.data?.data || []);
                                        XLSX.utils.book_append_sheet(wb, ws, "Output");
                                        XLSX.writeFile(wb, `seo-upload-result-${moment().format("DD-MM-YYYY")}.xlsx`);
                                      } else {
                                        window.alert(
                                          response.data?.message || "Failed to upload seo update."
                                        );
                                      }
                                    } catch (e) {
                                      console.log(e);
                                      window.alert("Failed to upload seo update.");
                                    }
                                  }
                                } else {
                                  window.alert("File is empty. Try again.");
                                }
                              } catch (err) {
                                console.error(err);
                                window.alert(
                                  err?.message || "File validation failed."
                                );
                              } finally {
                                setSeoUploadInProgress(false);
                              }
                            };
                            reader.readAsArrayBuffer(e.target.files[0]);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="span"
                          startIcon={<CloudUpload />}
                          disabled={seoUploadInProgress}
                        >
                          SEO Upload
                          <CircularProgress
                            color="inherit"
                            style={{
                              display: seoUploadInProgress ? "block" : "none",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                            }}
                          />
                        </Button>
                      </label>
                    </div>
                  </div>
                </>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                setTableSelectedData={(tableSelectedData) => setMainTableDataSelectedRows(tableSelectedData)}
                headerNames={headerNames}
                classes={classes}
              />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
